
//extracts amount from the correct property in the correct priority, and converts to ILS if asked. by default conversion is not taking plalce
function getAmount(obj, convertMeToILS) {
  const amount = obj.amount || obj.price || obj.meetingPrice || 0;
  if(convertMeToILS){
    return convertAmountToILS(amount, obj.currency);
  }
  return amount;
}

function convertAmountToILS(amount, currency) {
  switch (currency) {
    case 'USD':
      return amount * 3.77; // last udated on 18-01-2024
    case 'EUR':
      return amount * 4.10; // last udated on 18-01-2024
    case 'AUD':
      return amount * 2.48; // last udated on 18-01-2024
    case 'CAD':
      return amount * 2.79; // last udated on 18-01-2024
    case 'GBP':
      return amount * 4.78; // last udated on 18-01-2024
    default:
      return amount;
  }
}

function isPendingPayment(paymentId, paymentStatus, cancelled, meetingType, client) {
  if (cancelled === 'withoutPayment')
    return false;
  if (paymentId && paymentStatus === 'paid')
    return false;
  if (paymentId && paymentStatus === 'withoutPayment')
    return false;
  if (meetingType && meetingType.price !== undefined && parseInt(meetingType.price) === 0)
    return false;
  if (client && !client.isPaying)
    return false;
  return true;
}

function getMeetingPrice(payment, cancelled, meetingType, client, therapist, amountInDefaultCurrency=false) {

  //TODO: add condition, this condition is relevant only if payment.amount was changed by user.
  if (payment && Number.isInteger(payment.amount)) {
    return getAmount(payment, amountInDefaultCurrency) / payment.calendarEvents.length;
  }
  
  if (cancelled === 'withoutPayment') {
    return 0
  }

  // this is an exception for the client dominance
  if (meetingType && meetingType.price === 0) {
    return 0;
  }
   
  if (client && client.meetingPrice !== null){
    return getAmount(client, amountInDefaultCurrency);
  }
  if (meetingType && meetingType.price) {
    return getAmount(meetingType, amountInDefaultCurrency);
  }
  return therapist.defaultMeetingPrice || 0;
}

export default {
  isPendingPayment,
  getMeetingPrice,
  getAmount
}

