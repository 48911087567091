import axios from 'axios';

export default (function () {
  async function error(error){
    if(typeof error === 'string')
      error = {'message': error};
    await axios.post('/log/error',error)
  }
  async function info(info){
    if(typeof info === 'string')
      info = {'message': info};
    await axios.post('/log/info',info);
  }
  return {
    error,
    info
  };
}());