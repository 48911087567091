import React from 'react';
import logger from 'api/logger';
export default class ErrorBoundary extends React.Component {
    state = {
        error: null,
        errorInfo: null

    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo

        }, () =>{
            logger.error({ message: 'app crashed', error, errorInfo});
        })
        
    }

    render() {
        // if (this.state.errorInfo) {
        //     // Error path
        //     return (
        //         <div>
        //             <h2>Something went wrong.</h2>
        //             {JSON.stringify(this.state)}
        //             <details style={{ whiteSpace: 'pre-wrap' }}>
        //                 {this.state.error && this.state.error.toString()}
        //                 <br />
        //                 {this.state.errorInfo.componentStack}
        //             </details>
        //         </div>
        //     );
        // }
        // Normally, just render children
        return this.props.children;
    }
}