import axios from 'axios';
import Auth from '@aws-amplify/auth';
import ReactGA from 'react-ga';
import moment from "moment";

import logger from 'api/logger';
import general from '../miscellaneous/general';
import InactivityValidation from '../miscellaneous/inactivityValidation';

var user;
var isReady = false; //wating for amplify to return with current user if exists
var readyCallbacks = [];
var onLoginCallbacks = [];

axios.interceptors.request.use(async config => {
  config.headers.common['Accept-Language'] = general.language;
  // check auth validity before request is sent
  var authHeader = axios.defaults.headers.common['Authorization']
  if (authHeader && !isAuthExpired(authHeader)) {
    return config;
  }
  try {
    let session = await Auth.currentSession();
    authHeader = 'Bearer ' + session.accessToken.jwtToken;
    axios.defaults.headers.common['Authorization'] = authHeader;
    config.headers.common['Authorization'] = authHeader;
    
    //updateUser(session.accessToken.jwtToken, session.accessToken.payload.sub);
  } catch (err) {
    //console.log(err)
    updateUser(null);
    //document.location = urls.getLoginUrl()
  };


  return config;
},
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

axios.interceptors.response.use(
  (response) => {
    InactivityValidation.updateLastActivity();
    // If the response is successful, return it
    return response;
  },
  async (error) => {
    // If the response status is 401 (Unauthorized), handle it
    if (error.response && error.response.status === 401) {
      // redirect  
      await signOut();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

function isAuthExpired(authHeader) {
  let payloadBase64 = authHeader.replace('Bearer ', '').split('.')[1];
  let payloadBuff = new Buffer(payloadBase64, 'base64');
  let payload = JSON.parse(payloadBuff.toString('ascii'));
  let latencyBuffer = 60; //in sec
  let is = payload.exp < ((Date.now() / 1000) + latencyBuffer);
  return is;
}

function setUserDefaults() {
  if (!user.defaultMeetingPrice)
    user.defaultMeetingPrice = 400;
  if (!user.defaultMeetingDuration)
    user.defaultMeetingDuration = 50;
}

async function updateUser(jwtToken, cognitoId) {
  try {
    if (jwtToken && cognitoId) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
      //console.log(axios.defaults.headers.common['Authorization'])
      const response = await axios.get('users/byCognitoId/' + cognitoId);
      user = response.data;
      setUserDefaults()
      isReady = true;
      ReactGA.set({ userId: user.id }); /* google analytics */
      fireReadyCallbacks();

    } else {
      user = null;
      delete axios.defaults.headers.common['Authorization'];
    }
  } catch (e) {
    user = null;
  }
}

function signIn(email, password) {
  email = email.toLowerCase();
  isReady = false;
  return new Promise((resolve, reject) => {
    Auth.signIn(email, password)
      .then(async function (user1) {
        await updateUser(user1.signInUserSession.accessToken.jwtToken, user1.signInUserSession.accessToken.payload.sub);
        fireOnLoginCallbacks();
        resolve(user);
        logger.info('successful signin');
      })
      .catch(err => {
        console.log(err);
        reject(err);
        logger.info({ message: "failed signin", email: email, err });
      });
  });
}

function requestPasswordResetCode(email) {
  email = email.toLowerCase();
  return new Promise((resolve, reject) => {

    Auth.forgotPassword(email)
      .then(user => {
        //updateUser(user.signInUserSession.accessToken.jwtToken, user.signInUserSession.accessToken.payload.sub);
        resolve(user);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
}

function setNewPassword(email, resetCode, password) {
  email = email.toLowerCase();
  return new Promise((resolve, reject) => {

    Auth.forgotPasswordSubmit(email, resetCode, password)
      .then(user => {
        // debugger
        //updateUser(user.signInUserSession.accessToken.jwtToken, user.signInUserSession.accessToken.payload.sub);
        resolve(user);
        logger.info('password changed');

      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
}


async function verifyUser(id, email, code) {
  try {
    email = email.toLowerCase();
    const confirmRes = await Auth.confirmSignUp(email, code);
    return confirmRes;
  } catch (e) {
    return { err: e.message }
  }
}

function signUp(email, password, firstName, lastName) {
  isReady = false;
  email = email.toLowerCase();

  return new Promise((resolve, reject) => {
    Auth.signUp({
      username: email,
      password,
      attributes: {
        name: firstName + ' ' + lastName,
        email
      }
    }).then(function (user) {
      resolve(user);
    }).catch(err => {
      reject(err)
    })

  });
}

function signOut() {
  return new Promise((resolve, reject) => {
    Auth.signOut()
      .then(() => {
        logger.info('successful signout');
        general.clearSessionStorage();
        updateUser(null);
        resolve();
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
}

function ready() {
  return new Promise((resolve, reject) => {
    if (isReady)
      resolve(user);
    else
      readyCallbacks.push(resolve);
  });
}

function onLogin(callback) {
    onLoginCallbacks.push(callback);
}

function getLoggedInUser() {
  return user;
}

function isLoggedIn() {
  return !!user;
}

function fireOnLoginCallbacks() {
  for (let index = 0; index < onLoginCallbacks.length; index++) {
    onLoginCallbacks[index](user);
  }
}

function fireReadyCallbacks() {
  for (let index = 0; index < readyCallbacks.length; index++) {
    readyCallbacks[index](user);
  }
  readyCallbacks = [];
}

async function resendSignUp(email){
  await Auth.resendSignUp(email)
}

async function getClinic() {
  try {
    const { data } = await axios.get('clinic/');
    //updateUser(user.signInUserSession.accessToken.jwtToken, user.signInUserSession.accessToken.payload.sub);
    return data;
  } catch (e) {
    return { err: e.message }
  }
}
/* eslint-disable no-unused-vars */

function noInternetSolution() {

  updateUser('eyJraWQiOiJ0SEx1MVRiYlwvaEplYmc1bHV5TXkwTHRDYmJKYjR4ZTJ4NnZXTmtEcW1Raz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIzN2M4YzliNS0yZTE1LTQ3ZjctYTUwNS03NzA1YmFhYWFhOTQiLCJldmVudF9pZCI6ImMyNGIzZjI4LTVkMGQtMTFlOS1hYWQ2LTQ1YWM1NDc5NzVjOCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE1NTUwNjQ5MjgsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xXzc2Q2FOQXBCaSIsImV4cCI6MTU1NTEzNzc1OSwiaWF0IjoxNTU1MTM0MTU5LCJqdGkiOiI4NzEwMTNmMi03MDQwLTRlY2ItODMxMC1hM2JlMTJlNDhjNDEiLCJjbGllbnRfaWQiOiI1ajE3ZGQ4cmtpYThyaHI0NnU5NWtpZWVzZSIsInVzZXJuYW1lIjoiYWFhZGRkY0Bmci5jb20ifQ.ixrmfI0jP0tFCE4uKAWsN9hmNvi3qV3F479JVjF_cfqRfvmGFsI7zeZweJGREv96jYw5iZVxIGQ9xJTpiUmjgqmPtgTxJNHIVvURmMK92ETVpeiw_1kOvTTy3QDjqnUkvZe8IgEAj4oP7riuPts3GP6tH9aJoYxriKmQ48TI0aJEWBq2KALU2oT2Lh3URMIJfFhSfjkwur4NDf2KzB2hkVbS45JMROF8QxZWq62z5sGaITUQbtwMNSVBNEffLKnG-YScfW8oDQI3o_rL4kr-XOp6evJABoncmZkoDQZWwPMXjOQISaF0nWU5m-wDM3hC6ErbkDZ1D8vTxCvyC43zTQ', '37c8c9b5-2e15-47f7-a505-7705baaaaa94');
  return;
}

function initBrowserUnloadInactivitySignout() {
  window.addEventListener('beforeunload', function (e) {
    localStorage['unloaded'] = moment().toISOString()
  });
  if (localStorage['unloaded'] && moment(localStorage['unloaded']).add(10, 'minutes').isBefore(moment())) {
    localStorage['unloaded'] = null;
    signOut();
    window.location.replace('/');
  }
}


//first thing we set Therapist-Alias header if necessary. so all api calls have the header
function initTherapistAlias(){
  if(general.getSessionStorageData('therapistId')) {
    axios.defaults.headers.common['Therapist-Alias'] = general.getSessionStorageData('therapistId');
  }
}
function init() {
  //initBrowserUnloadInactivitySignout();//not sure what was the reason for that
  //return noInternetSolution();//no internet solution
  //gtag('set', {'user_id': 'USER_ID'}); // Set the user ID using signed-in user_id.

  initTherapistAlias();

  Auth.currentSession().then(session => {
    updateUser(session.accessToken.jwtToken, session.accessToken.payload.sub);
  })
    .catch(err => {
      //console.log(err)
      updateUser(null);
      isReady = true;
      fireReadyCallbacks();
    });


  // Auth.currentAuthenticatedUser().then(function (authUser) {
  //   console.log(authUser)
  //   updateUser(authUser);
  // }).catch( function (err) {
  //   debugger
  //   updateUser(null);
  //   isReady = true;
  //   fireReadyCallbacks();

  // });
}

async function reloadLoggedInUser() {
  const response = await axios.get('users/' + user.id);
  user = response.data;
  setUserDefaults()
  return user;
}

function expelNonLoggedInUser() {
  if (!isLoggedIn())
    window.location.reload();
}

const exportedModule = {
  updateUser,
  signUp,
  signIn,
  signOut,
  requestPasswordResetCode,
  setNewPassword,
  verifyUser,
  resendSignUp,
  getLoggedInUser,
  init,
  ready,
  isLoggedIn,
  reloadLoggedInUser,
  onLogin,
  expelNonLoggedInUser,
  getClinic
}

export default exportedModule;