import {
  infoColor,
  inputColor,
  labelColor,
  inputBorderColor
} from "assets/jss/material-dashboard-pro-react.jsx";

export default {
  direction: "rtl",
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920  
    }
  },
  palette: {
    primary: {
      main: infoColor
    },

  },
  typography: {
    fontFamily: ["Arimo"],
    h1: {
      fontSize: "34px", 
      fontWeight: 500, 
      marginBlockStart: "0.67em",
      marginBlockEnd: "0.67em"
    },
    h2: {fontSize:  "20px"},
    h3: {fontSize:  "19px"},
    h4: {fontSize:  "18px", fontWeight: 400},
    h5: {fontSize:  "17px"},
    h6: {fontSize:  "16px"},
    subtitle1: {fontSize:  "15px"},
    body1: {fontSize:  "15px"},
    body2: {fontSize:  "0.875rem"},
    body3: {fontSize:  "16px", fontWeight:  "bold"},
    body4: {fontSize:  "13px"},

    select: {fontSize:  "16px"},
    BUTTON: { fontSize: "16px", fontWeight: 300 }
  },
  overrides: {
    MuiInput: {
      root: {
        color: inputColor,
        '&:before': {
          borderColor: inputBorderColor + '!important'
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: labelColor
      }
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 1.5px) scale(0.9)"
      }
    },
    MuiButton:
    { 
      outlined: {
        borderColor: "white",
        color: "white"
      }
    },
    MuiPickersBasePicker: {
      container: {
        '& .MuiPickersYear-yearSelected':{
          fontSize: 30
        },
        '& .MuiPickersToolbarText-toolbarTxt': {
          color: 'white',
          fontSize: 20
        },
        '& .MuiPickersToolbarText-toolbarBtnSelected': {
          color: 'white',
        },
        '& .MuiTypography-h4': {
          color: 'white',
          fontSize: 30
        },
        '& .MuiPickersDay-daySelected': {
          color: 'white',
          '& .MuiTypography-root': {
            fontWeight: "bold"
          }
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '16px'
      },
    },
  }
};