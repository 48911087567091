import moment from "moment";
import Authentication from "api/Authentication";

const InactivityValidation = (function () {

  const MAX_INACTIVITY_TIME_IN_MINUTES = 70;

  function updateLastActivity() {
    localStorage.setItem('lastActivity', new Date().getTime());
  }

  function validate() {
    const lastInteractionTime = localStorage.getItem('lastActivity');
    if (lastInteractionTime) {
      const currentTime = moment(new Date());
      const lastInteraction = moment(new Date(parseInt(lastInteractionTime)));
      const duration = moment.duration(currentTime.diff(lastInteraction));
      if (duration.asMinutes() > MAX_INACTIVITY_TIME_IN_MINUTES) {
        Authentication.signOut();
        return;
      }
    }
  }

  return {
    updateLastActivity,
    validate
  };
}());

export default InactivityValidation;
