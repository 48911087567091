// import amplifyConfigDev from './aws-exports.dev';
// import amplifyConfigProd from './aws-exports.prod';

// const amplifyConfig = process.env.REACT_APP_ENV === "dev" ? amplifyConfigDev : amplifyConfigProd


const amplifyConfig = {
 
  Auth: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_AMPLIFYCONFIG_IDENTITYPOOLID,

    // (required)- Amazon Cognito Region
    region: process.env.REACT_APP_AMPLIFYCONFIG_REGION,

    // (optional) - Amazon Cognito Federated Identity Pool Region 
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: process.env.REACT_APP_AMPLIFYCONFIG_IDENTITYPOOLREGION,

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AMPLIFYCONFIG_USERPOOLID,

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: process.env.REACT_APP_AMPLIFYCONFIG_USERPOOLWEBCLIENTID,

  

    // (optional) - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // - Cookie domain (only required if cookieStorage is provided)
      domain: process.env.REACT_APP_AMPLIFYCONFIG_COOKIESTORAGE_DOMAIN,
      secure: true,
      path: "/",
      expires: 3650
      }
    }
  }

export default amplifyConfig;
