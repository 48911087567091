import axios from 'axios';
import axiosRetry from 'axios-retry';


axios.defaults.baseURL = process.env.REACT_APP_APIURL;
if(!axios.defaults.baseURL)
  axios.defaults.baseURL = 'https://api.smartclinic.co.il/';

const retryDelay = (retryNumber = 0) => {
  return retryNumber * 1000;
};

const shouldRetry = (error) => {
  // retry on Network Error & 5xx responses
  if (axiosRetry.isRetryableError(error)) {
    if (error && error.config && error.config.method)
      return error.config.method.toUpperCase() === 'GET';
  }
  return false;
}

axiosRetry(axios, {
  retries: 2,
  retryDelay,
  retryCondition: shouldRetry
});

export default axios;
