import lazyWithRetry from '../miscellaneous/lazyWithRetry';

const Dashboard = lazyWithRetry(() => import("views/layouts/Dashboard.jsx"));
const Auth = lazyWithRetry(() => import("views/pages/Auth/Auth.jsx"));
const DocumentViewer = lazyWithRetry(() => import("views/pages/DocumentViewer/"));
const SubscriptionSuccess = lazyWithRetry(() => import("views/pages/Subscription/Success"));
const SubscriptionFailure = lazyWithRetry(() => import("views/pages/Subscription/Failure"));
const UrlExpired = lazyWithRetry(() => import("views/pages/UrlExpired"));

var indexRoutes = [
  { path: "/documentViewer/:id", name: "צפייה במסמך", component: DocumentViewer },
  { path: "/subscription/success", component: SubscriptionSuccess },
  { path: "/subscription/failure", component: SubscriptionFailure },
  { path: "/dashboard", name: "Home", component: Dashboard },
  { path: "/auth", name: "auth", component: Auth },
  { path: "/urlexpired", name: "UrlExpired", component: UrlExpired },
  { redirect: true, path: "/", pathTo: "/dashboard/home"}

];

export default indexRoutes;
